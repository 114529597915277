<template>
  <div class="p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold">Add User</h1>
      </div>
      <form @submit.prevent="submitUser" class="max-w-3xl space-y-5">
        <div class="text-lg space-y-5">
          <div>
            <label
              for="user_name"
              class="block text-sm font-medium text-gray-600"
            >
              Name <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="document.user_name"
                required
                name="user_name"
                class="w-full border rounded px-4 py-2"
              />
            </div>
          </div>

          <div>
            <label
              for="user_email"
              class="block text-sm font-medium text-gray-600"
            >
              Email <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="document.user_email"
                required
                type="email"
                name="user_email"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              />
            </div>
          </div>

          <div>
            <label
              for="user_password"
              class="block text-sm font-medium text-gray-600"
            >
              Password <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="document.user_password"
                required
                name="user_password"
                class="w-full border rounded px-4 py-2"
              />
            </div>
          </div>

          <div>
            <label
              for="user_role"
              class="block text-sm font-medium text-gray-600"
            >
              Role <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <select
                v-model="document.user_role"
                required
                name="user_role"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value=""> </option>
                <option value="admin">Admin</option>
                <option value="office">Office</option>
                <option value="estimator">Estimator</option>
              </select>
            </div>
          </div>

          <div>
            <label
              for="user_status"
              class="block text-sm font-medium text-gray-600"
            >
              Status <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <select
                v-model="document.user_status"
                name="user_status"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
        </div>

        <div class="pt-2 flex justify-center">
          <button
            type="submit"
            class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
          >
            <div v-if="isPending" class="spinner"></div>
            Save
          </button>
        </div>

        <div v-if="error" class="text-center text-red-600 font-semibold">
          {{ error }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { functions, timestamp } from "@/firebaseConfig";
import { useRoute, useRouter } from "vue-router";
import useCollection from "@/composables/useCollection";

export default {
  props: ["id", "user"],
  setup(props) {
    const isPending = ref(false);
    const route = useRoute();
    const router = useRouter();
    const createUser = functions.httpsCallable("createUser");
    const setCustomUserClaims = functions.httpsCallable("setCustomUserClaims");

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, setDoc } = useCollection(`businesses/${business_id}/users`);

    const document = ref({ user_status: "active" });

    const submitUser = async () => {
      error.value = null;
      isPending.value = true;
      await createUser({
        ...(document.value.user_status == "inactive" && { disabled: true }),
        displayName: document.value.user_name,
        email: document.value.user_email,
        password: document.value.user_password,
      })
        .then(async (result) => {
          await setCustomUserClaims({
            business_id: business_id,
            uid: result.data.uid,
            user_role: document.value.user_role,
          });
          await setDoc(result.data.uid, {
            business_id: business_id,
            createdAt: timestamp(),
            createdBy: props.user.uid,
            user_name: document.value.user_name,
            user_email: document.value.user_email,
            user_role: document.value.user_role,
            user_status: document.value.user_status,
          });
          isPending.value = false;
          if (!error.value) {
            router.push(route.path.substring(0, route.path.lastIndexOf("/")));
          }
        })
        .catch((err) => {
          error.value = err.message;
        });
    };

    return {
      router,
      error,
      document,
      submitUser,
      isPending,
    };
  },
};
</script>
